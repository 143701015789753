* {
  margin: 0;
  padding: 0;
}

*:focus {
  outline: 0;
}

.page {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.si-content {
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 208, 0);
  color:black;
  min-width: 35vw;
  max-width: 90vw;
  align-items: center;
  font-weight: 500;
  padding: 2.5em 0em 0em 0em;
  border-radius: 4px;
}


.si-input {
  display: flex;
  min-width: 80%;
  height: 10em;
  padding: 10px;
  box-sizing: border-box;
  border: none;
  border-radius: 4px;
  background-color: #f8f8f8;
  resize: none;
  font-size: medium;
}

.si-btn-submit {
  border: none;
  background-color: rgb(255, 136, 0);
  min-width: 80%;
  height: 3em;
  margin-top: 2em;
  margin-bottom: 1em;
  border-radius: 4px;
  font-size: medium;
}

.si-btn-submit:hover {
  cursor: pointer;
}

.si-title {
  margin-bottom: 0.5em;
}

.si-sub-title {
  margin: 0 0 1.5em 0;
  max-width: 90%;
  text-align: center;
}

.si-url {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  height: 2em;
}

.si-copy {
  border: none;
  background-color: #282c34;
  color: rgb(255, 208, 0);
  width: 3.5em;
  height: 2em;
  border-radius: 4px;
  margin-left: 1em;
}

.si-copy:hover{
  cursor: pointer;
}

.si-try {
  width: 4.5em;
  height: 2.5em;
  margin-top: 0em;
}

.vi-content {
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 208, 0);
  color:black;
  min-width: 40vw;
  max-width: 95vw;
  align-items: center;
  font-weight: 500;
  padding: 2em 0em 2em 0em;
  border-radius: 4px;
}

.vi-title {
  margin-bottom: 0.5em;
}

.vi-row {
  display: flex;
  flex-direction: row;
  background-color:rgb(255, 187, 0);
  min-width: 80%;
  height: 3em;
  justify-content: space-between;
  border-radius: 4px;
  margin-top: 1em;
}

.vi-row-cell {
  display: flex;
  flex-direction: row;
  margin: 1em;
  align-items: center;
}

.vi-invoice {
  display: flex;
  flex-direction: row;
  word-break: break-all;
  margin: 1em 0.5em 0 0.5em;
  padding: 5px;
  min-width: 5em;
  max-width: 40em;
  height: auto;
  text-align: center;
}

.vi-copy {
  width: 3.5em;
  height: 2em;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 1em;
  line-height: 30px;
  border: none;
  border-radius: 4px;
  background-color: #282c34;
  color: rgb(255, 208, 0);
}

.vi-copy:hover {
  cursor: pointer;
}

.vi-fiat {
  margin-left: 0.25em;
}

.vi-qrcode {
  width: 12em;
  height: 12em;
}

.vi-expire {
  margin-bottom: 0.5em;
  min-width: 8em;
  padding: 0em 0.5em 0 0.5em;
  border-radius: 4px;
}